import PropTypes from 'prop-types'
import { MenuSubList } from '../'
import React from 'react'
import { connect } from 'react-redux'
import { useDfpSlot } from 'SRC/modules/common/hooks/useDfpSlot'
import { getGroups } from 'SRC/modules/groups/selectors'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import config from 'SRC/config/config.json'

export const TransportSubMenu = ({ items }) => {
  const { t } = useTranslation('common')

  useDfpSlot({
    id: config.banners.groupsSubMenu?.slotId || null,
    sizes: config.banners.groupsSubMenu?.sizes || [],
    path: config.banners.groupsSubMenu ? `/${config.banners.dfpNetworkId}/${config.banners.groupsSubMenu.adUnit}` : null
  })

  const list1 = Array.isArray(items) && items.length ? items.slice(0, items.length / 2) : []
  const list2 = Array.isArray(items) && items.length ? items.slice(items.length / 2) : []

  return (
    <div className='kategorije-mega-menu'>
      <div className='kategorije-menu'>
        {
          list1.length ? <MenuSubList items={list1} /> : []
        }
        {
          list2.length ? <MenuSubList items={list2} /> : []
        }
        <hr />
        <Link href={'/categories'} as={'/kategorije'}>
          <a className='pogledaj-sve-btn'>
            <i className='ico-gavni-meni-pogledaj-sve-kategorije ico' />
            {t('Pogledaj sve kategorije i podkategorije')}
          </a>
        </Link>
      </div>
      <div className='kategorije-img'>
        {config.banners.groupsSubMenu ? (
          <div id={config.banners.groupsSubMenu.slotId} style={{ width: 300, height: 250 }} />
        ) : null}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  items: getGroups(state)
})

TransportSubMenu.propTypes = {
  items: PropTypes.array.isRequired
}

TransportSubMenu.defaultProps = {
  items: []
}

export default connect(mapStateToProps)(TransportSubMenu)
