/* eslint-disable @next/next/next-script-for-ga */
/* eslint-disable @next/next/no-sync-scripts */
import React from 'react'
import PropTypes from 'prop-types'
import NextHead from 'next/head'
import { connect } from 'react-redux'
import { useRouter } from 'next/router'
import { fetchPageMetaTags } from 'SRC/modules/common/actions'
import useTranslation from 'next-translate/useTranslation'
import { getParsedFilterValues } from 'SRC/modules/ads/filter/functions'
import { getPageMetaTags, getPageMetaTagsSingle } from 'SRC/modules/common/selectors'
import { getInfo } from 'SRC/modules/users/detail/selectors'
import config from 'SRC/config/config.json'
import { getTitleForCategory, getTitleForSearchPage, getCannonicalUrl } from './helpers'

const defaultTitle = config.head.title
const defaultDescription = config.head.description
const defaultImageUrl = config.head.shareImage

const GTMTag = () => {
  return (
    <NextHead>
      <script key='gtm-script-tag' dangerouslySetInnerHTML={{
        __html: `(function (w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event: 'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f)
      })(window,document,'script','dataLayer', '${config.head.gaID}')`
      }} />
    </NextHead>
  )
}

const TopTags = () => {
  return (
    <NextHead>
      <meta key='charset-tag' charSet='UTF-8' />
      <meta key='viewport-tag' name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1' />
      <link key='favicon-tag' rel='icon' href='/icons/favicon-48x48.png' />
      <link key='open-sans-tag' href='https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700' rel='stylesheet' />

      {/* globalThis polyfill */}
      <script key='gthis-script-tag' src='//unpkg.com/@ungap/global-this' />
      <script key='gpt-script-tag' async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
      <script key='gtag-setup-script-tag' src='/js/gtag-setup.js' />
      <script key='saas-script-tag' async src='https://stpd.cloud/saas/4118' />
      <script key='recaptcha-script-tag' src={`https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`}></script>
    </NextHead>
  )
}

const PWATags = () => {
  return (
    <NextHead>
      <link key='manifest-tag' rel='manifest' href='/manifest.json' />
      <link key='apple-touch-icon-tag' rel='apple-touch-icon' href='/icon.png' />
      <meta key='theme-color-tag' name='theme-color' content='#fff' />
    </NextHead>
  )
}

const DefaultTags = ({ title, asPath, pageMetaTags, cannonicalTagDefault, noIndex }) => {
  const findMetaTag = asPath && Array.isArray(pageMetaTags)
    ? pageMetaTags.find(pmt => pmt.page === asPath.replace(/\/me|\/ru|\/en|\/ua\/de\/al\/tr/, ''))
    : null

  let titleTag = title || defaultTitle
  let description = defaultDescription
  let cannonicalTag = cannonicalTagDefault || null

  let ogType = 'website'
  let ogTitle = defaultTitle
  let ogUrl = config.siteUrl
  let ogDescription = defaultDescription
  let ogImageUrl = defaultImageUrl
  let ogImageAlt = "AutoDiler Crna Gora"

  let twTitle = "AutoDiler Oglasi"
  let twDescription = "AutoDiler Oglasi"
  let twImage = 'https://autodiler.me/img/autodiler-share.jpg'
  let twImageAlt = 'AutoDiler Crna Gora'

  if (findMetaTag) {
    if (findMetaTag.title) titleTag = findMetaTag.title
    if (findMetaTag.meta_description) description = findMetaTag.meta_description
    if (findMetaTag.cannonical_tag) cannonicalTag = findMetaTag.cannonical_tag

    if (findMetaTag.og_title) ogTitle = findMetaTag.og_title
    if (findMetaTag.og_url) ogUrl = findMetaTag.og_url
    if (findMetaTag.og_description) ogDescription = findMetaTag.og_description
    if (findMetaTag.og_image) ogImageUrl = findMetaTag.og_image
    if (findMetaTag.og_image_alt) ogImageAlt = findMetaTag.og_image_alt

    if (findMetaTag.tw_title) twTitle = findMetaTag.tw_title
    if (findMetaTag.tw_description) twDescription = findMetaTag.tw_description
    if (findMetaTag.tw_image) twImage = findMetaTag.tw_image
    if (findMetaTag.tw_image_alt) twImageAlt = findMetaTag.tw_image_alt
  }

  return (
    <React.Fragment>
      <GTMTag />
      <TopTags key='other-tags' />

      {!noIndex ? <NextHead>
        <title key='title-tag'>{titleTag}</title>
        <meta key='meta-description-tag' name='description' content={description} />
        {cannonicalTag ? <link key='canonical-tag' rel="canonical" href={cannonicalTag} /> : null}

        <meta key='og-type-tag' property="og:type" content={ogType} />
        <meta key='og-title-tag' property='og:title' content={ogTitle} />
        <meta key='og-url-tag' property='og:url' content={ogUrl} />
        <meta key='og-description-tag' property='og:description' content={ogDescription} />
        <meta key='og-image-tag' property='og:image' content={ogImageUrl} />
        <meta key='og-image-alt-tag' property="og:image:alt" content={ogImageAlt} />

        <meta key='tw-title-tag' name="twitter:title" content={twTitle} />
        <meta key='tw-description-tag' name="twitter:description" content={twDescription} />
        <meta key='tw-image-tag' name="twitter:image" content={twImage} />
        <meta key='tw-image-alt-tag' name="twitter:image:alt" content={twImageAlt} />
      </NextHead> : (
        <NextHead>
          <meta name='robots' content='noindex' />
        </NextHead>
      )}

      <PWATags key='pwa-tags' />
    </React.Fragment>
  )
}

const AdPageTags = ({ ad, noIndex }) => {
  const { t, lang } = useTranslation('common')
  const title = ad ? (getTitleForCategory({ ...ad, t }) || ad.titleCompiled) : defaultTitle
  const description = ad ? ad.description : defaultDescription
  const adUrl = ad ? ad.link : config.siteUrl
  let imageUrl = defaultImageUrl

  let cannonicalUrl = 'https://autodiler.me'
  if (lang !== 'me') cannonicalUrl = `https://autodiler.me/${lang}`

  if (ad && ad.category) cannonicalUrl += `/${ad.category.seo}/${ad.seo}`

  if (ad && Array.isArray(ad.media) && ad.media.length) {
    const mainImg = ad.media.find(img => img.main)

    if (mainImg && mainImg.fd) imageUrl = mainImg.fd
  }

  return (
    <React.Fragment>
      <GTMTag />
      <TopTags key='other-tags' />

      {ad && !noIndex ? (
        <NextHead>
          <title key='title-tag'>{title}</title>
          <meta key='meta-description-tag' name='description' content={description} />
          <link key='canonical-tag' rel="canonical" href={cannonicalUrl} />

          <meta key='og-type-tag' property='og:type' content='article' />
          <meta key='og-title-tag' property='og:title' content={title} />
          <meta key='og-url-tag' property='og:url' content={adUrl} />
          <meta key='og-description-tag' property='og:description' content={description} />
          <meta key='og-image-tag' property='og:image' content={imageUrl} />
          <meta key='og-image-alt-tag' property="og:image:alt" content={title || "AutoDiler Crna Gora"} />

          <meta key='tw-title-tag' name='twitter:title' content={title} />
          <meta key='tw-description-tag' name='twitter:description' content={description} />
          <meta key='tw-image-tag' name="twitter:image" content={imageUrl} />
          <meta key='tw-image-alt-tag' name="twitter:image:alt" content={title || "AutoDiler Crna Gora"} />

          <meta key='twitter-domain-tag' name='twitter:domain' content={config.siteUrl} />
          <meta key='twitter-googleplay-name-tag' name='twitter:app:name:googleplay' content='AutoDiler - autodiler.me' />
          <meta key='twitter-googleplay-id-tag' name='twitter:app:id:googleplay' content='me.autodiler' />
        </NextHead>
      ) : (
        <NextHead>
          <meta name='robots' content='noindex' />
        </NextHead>
      )}

      <PWATags key='pwa-tags' />
    </React.Fragment>
  )
}

const CategoryPageTags = ({ categoryTags, cannonical_url, noIndex }) => {
  return (
    <React.Fragment>
      <GTMTag />
      <TopTags key='other-tags' />

      {categoryTags && !noIndex ? (
        <NextHead>
          <title key='title-tag'>{categoryTags.title || defaultTitle}</title>
          <meta key='meta-description-tag' name='description' content={categoryTags.meta_description || defaultDescription} />
          {categoryTags.cannonical_tag ?
            <link key='canonical-tag' rel="canonical" href={categoryTags.cannonical_tag} /> : cannonical_url ?
              <link key='canonical-tag' rel="canonical" href={cannonical_url} /> : null}

          <meta key='og-type-tag' property="og:type" content={categoryTags.og_type || "website"} />
          <meta key='og-title-tag' property='og:title' content={categoryTags.og_title || defaultTitle} />
          <meta key='og-url-tag' property='og:url' content={categoryTags.og_url || config.siteUrl} />
          <meta key='og-description-tag' property='og:description' content={categoryTags.og_description || defaultDescription} />
          <meta key='og-image-tag' property='og:image' content={categoryTags.og_image || defaultImageUrl} />
          <meta key='og-image-alt-tag' property="og:image:alt" content={categoryTags.og_image_alt || "AutoDiler Crna Gora"} />

          <meta key='tw-title-tag' name="twitter:title" content={categoryTags.tw_title || "AutoDiler Oglasi"} />
          <meta key='tw-description-tag' name="twitter:description" content={categoryTags.tw_description || "AutoDiler Oglasi"} />
          <meta key='tw-image-tag' name="twitter:image" content={categoryTags.tw_image || "https://autodiler.me/img/autodiler-share.jpg"} />
          <meta key='tw-image-alt-tag' name="twitter:image:alt" content={categoryTags.tw_image_alt || "AutoDiler Crna Gora"} />
        </NextHead>
      ) : (
        <NextHead>
          <meta name='robots' content='noindex' />
        </NextHead>
      )}

      <PWATags key='pwa-tags' />
    </React.Fragment>
  )
}

const SearchPageTags = ({ categoryTags, filterValues, cannonicalTagDefault }) => {
  const title = getTitleForSearchPage({
    categoryTagTitle: categoryTags.title,
    urlSpecs: categoryTags.urlSpecs,
    defaultTitle,
    ...filterValues
  })

  return (
    <React.Fragment>
      <GTMTag />
      <TopTags key='other-tags' />

      {categoryTags ? (
        <NextHead>
          <title key='title-tag'>{title}</title>
          <meta key='meta-description-tag' name='description' content={categoryTags.meta_description || defaultDescription} />
          {cannonicalTagDefault
            ? <link key='canonical-tag' rel="canonical" href={cannonicalTagDefault} />
            : categoryTags.cannonical_tag
              ? <link key='canonical-tag' rel="canonical" href={categoryTags.cannonical_tag} />
              : null
          }

          <meta key='og-type-tag' property="og:type" content={categoryTags.og_type || "website"} />
          <meta key='og-title-tag' property='og:title' content={categoryTags.og_title || defaultTitle} />
          <meta key='og-url-tag' property='og:url' content={categoryTags.og_url || config.siteUrl} />
          <meta key='og-description-tag' property='og:description' content={categoryTags.og_description || defaultDescription} />
          <meta key='og-image-tag' property='og:image' content={categoryTags.og_image || defaultImageUrl} />
          <meta key='og-image-alt-tag' property="og:image:alt" content={categoryTags.og_image_alt || "AutoDiler Crna Gora"} />

          <meta key='tw-title-tag' name="twitter:title" content={categoryTags.tw_title || "AutoDiler Oglasi"} />
          <meta key='tw-description-tag' name="twitter:description" content={categoryTags.tw_description || "AutoDiler Oglasi"} />
          <meta key='tw-image-tag' name="twitter:image" content={categoryTags.tw_image || "https://autodiler.me/img/autodiler-share.jpg"} />
          <meta key='tw-image-alt-tag' name="twitter:image:alt" content={categoryTags.tw_image_alt || "AutoDiler Crna Gora"} />
        </NextHead>
      ) : null}

      <PWATags key='pwa-tags' />
    </React.Fragment>
  )
}

const UserPageTags = ({ user, title, cannonical_url, noIndex }) => {
  const findProfilePicture = Array.isArray(user?.media)
    ? user.media.find(photo => photo.title === 'profile-picture' && photo.visible)
    : null

  return (
    <React.Fragment>
      <GTMTag />
      <TopTags key='other-tags' />

      {user && !noIndex ? (
        <NextHead>
          <title key='title-tag'>{user.seoTitle || title}</title>
          <meta key='meta-description-tag' name='description' content={user.seoDescription || defaultDescription} />
          {cannonical_url ? <link key='canonical-tag' rel="canonical" href={cannonical_url} /> : null}

          <meta key='og-type-tag' property="og:type" content="website" />
          <meta key='og-title-tag' property='og:title' content={user.seoTitle || title} />
          {cannonical_url ? <meta key='og-url-tag' property='og:url' content={cannonical_url} /> : null}
          <meta key='og-description-tag' property='og:description' content={user.seoDescription || defaultDescription} />

          {findProfilePicture ? (
            <>
              <meta
                key='og-image-tag'
                property='og:image'
                content={`https://slike.autodiler.me/${findProfilePicture.fd}` || defaultImageUrl}
              />
              <meta key='og-image-alt-tag' property="og:image:alt" content={user.name || user.username} />

              <meta
                key='tw-image-tag'
                name="twitter:image"
                content={`https://slike.autodiler.me/${findProfilePicture.fd}` || defaultImageUrl}
              />
              <meta key='tw-image-alt-tag' name="twitter:image:alt" content={user.name || user.username} />
            </>
          ) : null}

          <meta key='tw-title-tag' name="twitter:title" content={user.seoTitle || title} />
          <meta key='tw-description-tag' name="twitter:description" content={user.seoDescription || defaultDescription} />
        </NextHead>
      ) : (
        <NextHead>
          <meta name='robots' content='noindex' />
        </NextHead>
      )}

      <PWATags key='pwa-tags' />
    </React.Fragment>
  )
}

export const Head = (props) => {
  const { pageMetaTags, pageMetaTagsSingle, fetchPageMetaTags, ad, title, pageUrlItems, userDetails, isUserSearch } = props

  const router = useRouter()
  const { lang } = useTranslation('common')

  const locale = lang?.toUpperCase() || 'ME'

  const cannonicalTagDefault = getCannonicalUrl(router.asPath, lang)

  switch (router.pathname) {
    case '/category':
      return (
        <CategoryPageTags
          key={`category-page-tags-${router.asPath}`}
          categoryTags={pageMetaTagsSingle}
          cannonical_url={cannonicalTagDefault}
          noIndex={(router.asPath || '').includes('/all/1')}
        />
      )
    case '/search':
      if (isUserSearch) {
        const cannonical_url = userDetails ? `https://autodiler.me${router.asPath}` : null

        return (
          <UserPageTags
            user={userDetails}
            title={title}
            canonicalUrl={cannonical_url}
            noIndex={(router.asPath || '').includes('/all/1')}
          />
        )
      }

      if (router.pathname === '/search' && !pageMetaTagsSingle) {
        return (
          <DefaultTags
            asPath={router.asPath}
            pageMetaTags={pageMetaTags}
            cannonicalTagDefault={cannonicalTagDefault}
          />
        )
      }

      const possibleValues = (router.query['0'] || '').split('/')

      const filterValues = getParsedFilterValues({
        filterValues: router.query,
        pageMetaTags: pageMetaTagsSingle,
        pageUrlItems: pageUrlItems || [],
        possibleValues,
        locale
      })

      return (
        <SearchPageTags
          key={`search-page-tags-${router.asPath}`}
          filterValues={filterValues}
          categoryTags={pageMetaTagsSingle}
          cannonicalTagDefault={cannonicalTagDefault}
        />
      )
    case '/ad': {
      const noIndex = !ad || !router.query?.category || !isNaN(router.query.category)

      return <AdPageTags ad={ad} noIndex={noIndex} />
    }
    case '/user': {
      const cannonical_url = userDetails ? `https://autodiler.me${router.asPath}` : null

      return (
        <UserPageTags
          user={userDetails}
          title={title}
          cannonical_url={cannonical_url}
          noIndex={(router.asPath || '').includes('/all/1')}
        />
      )
    }

    default:
      return (
        <DefaultTags
          title={title}
          asPath={router.asPath}
          pageMetaTags={pageMetaTags}
          cannonicalTagDefault={cannonicalTagDefault}
          noIndex={router.pathname === '/_error'}
        />
      )
  }
}

Head.propTypes = {
  ad: PropTypes.object,
  title: PropTypes.string,
  pageUrlItems: PropTypes.array,
  pageMetaTags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    page: PropTypes.string.isRequired,
    categoryId: PropTypes.number,
    groupId: PropTypes.number,
    title: PropTypes.string.isRequired,
    meta_description: PropTypes.string,
    description: PropTypes.string,
    h1: PropTypes.string,
    h2: PropTypes.string,
    cannonical_tag: PropTypes.string,
    og_title: PropTypes.string,
    og_description: PropTypes.string,
    og_url: PropTypes.string,
    og_type: PropTypes.string,
    og_image: PropTypes.string,
    og_image_alt: PropTypes.string,
    tw_title: PropTypes.string,
    tw_description: PropTypes.string,
    tw_image: PropTypes.string,
    tw_image_alt: PropTypes.string
  })),
  pageMetaTagsSingle: PropTypes.shape({
    id: PropTypes.number.isRequired,
    page: PropTypes.string.isRequired,
    categoryId: PropTypes.number,
    groupId: PropTypes.number,
    title: PropTypes.string.isRequired,
    meta_description: PropTypes.string,
    description: PropTypes.string,
    h1: PropTypes.string,
    h2: PropTypes.string,
    cannonical_tag: PropTypes.string,
    og_title: PropTypes.string,
    og_description: PropTypes.string,
    og_url: PropTypes.string,
    og_type: PropTypes.string,
    og_image: PropTypes.string,
    og_image_alt: PropTypes.string,
    tw_title: PropTypes.string,
    tw_description: PropTypes.string,
    tw_image: PropTypes.string,
    tw_image_alt: PropTypes.string
  })
}

DefaultTags.propTypes = {
  title: PropTypes.string,
  pageMetaTags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    page: PropTypes.string.isRequired,
    categoryId: PropTypes.number,
    groupId: PropTypes.number,
    title: PropTypes.string.isRequired,
    meta_description: PropTypes.string,
    description: PropTypes.string,
    h1: PropTypes.string,
    h2: PropTypes.string,
    cannonical_tag: PropTypes.string,
    og_title: PropTypes.string,
    og_description: PropTypes.string,
    og_url: PropTypes.string,
    og_type: PropTypes.string,
    og_image: PropTypes.string,
    og_image_alt: PropTypes.string,
    tw_title: PropTypes.string,
    tw_description: PropTypes.string,
    tw_image: PropTypes.string,
    tw_image_alt: PropTypes.string
  })),
  asPath: PropTypes.string,
  cannonicalTagDefault: PropTypes.string,
  noIndex: PropTypes.bool
}

AdPageTags.propTypes = {
  ad: PropTypes.object,
  noIndex: PropTypes.bool
}

CategoryPageTags.propTypes = {
  categoryTags: PropTypes.shape({
    id: PropTypes.number.isRequired,
    page: PropTypes.string.isRequired,
    categoryId: PropTypes.number,
    groupId: PropTypes.number,
    title: PropTypes.string.isRequired,
    meta_description: PropTypes.string,
    description: PropTypes.string,
    h1: PropTypes.string,
    h2: PropTypes.string,
    cannonical_tag: PropTypes.string,
    og_title: PropTypes.string,
    og_description: PropTypes.string,
    og_url: PropTypes.string,
    og_type: PropTypes.string,
    og_image: PropTypes.string,
    og_image_alt: PropTypes.string,
    tw_title: PropTypes.string,
    tw_description: PropTypes.string,
    tw_image: PropTypes.string,
    tw_image_alt: PropTypes.string
  }),
  cannonical_url: PropTypes.string,
  noIndex: PropTypes.bool
}

SearchPageTags.propTypes = {
  categoryTags: PropTypes.shape({
    id: PropTypes.number.isRequired,
    page: PropTypes.string.isRequired,
    categoryId: PropTypes.number,
    groupId: PropTypes.number,
    title: PropTypes.string.isRequired,
    meta_description: PropTypes.string,
    description: PropTypes.string,
    h1: PropTypes.string,
    h2: PropTypes.string,
    cannonical_tag: PropTypes.string,
    og_title: PropTypes.string,
    og_description: PropTypes.string,
    og_url: PropTypes.string,
    og_type: PropTypes.string,
    og_image: PropTypes.string,
    og_image_alt: PropTypes.string,
    tw_title: PropTypes.string,
    tw_description: PropTypes.string,
    tw_image: PropTypes.string,
    tw_image_alt: PropTypes.string
  }),
  filterValues: PropTypes.object,
  cannonicalTagDefault: PropTypes.string,
  noIndex: PropTypes.bool
}

UserPageTags.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    seo: PropTypes.string,
    seoTitle: PropTypes.string,
    seoDescription: PropTypes.string,
    username: PropTypes.string.isRequired,
    userType: PropTypes.shape({
      name: PropTypes.string,
      namePlu: PropTypes.string,
      seo: PropTypes.string,
      seoPlu: PropTypes.string,
    }).isRequired
  }),
  title: PropTypes.string,
  canonical_url: PropTypes.string,
  noIndex: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    pageMetaTags: getPageMetaTags(state),
    pageMetaTagsSingle: getPageMetaTagsSingle(state),
    userDetails: getInfo(state)
  }
}

export default connect(mapStateToProps, { fetchPageMetaTags })(Head)
