import PropTypes from 'prop-types'
import React from 'react'
import { Head, Footer, Sidebar, Header } from '../../layout'
import { ErrorNotification } from '../../../ui/ErrorNotification'
import { connect } from 'react-redux'
import { useDfpSlot } from 'SRC/modules/common/hooks/useDfpSlot'
import { getAuthError } from '../../users/Auth/selectors'
import { setAuthError } from '../../users/Auth/actions'
import { getAppLoading, getIsMobileDevice } from '../../common/selectors'
import { setIsMobileDevice } from '../../common/actions'
import { getPaginationParams } from 'SRC/modules/ads/pagination/selectors'
import { getCurrentAd } from 'SRC/modules/ads/detail/selectors'
import { CookieConsent } from 'SRC/modules/common/components/CookieConsent'
import TopLoader from 'react-top-loader'
import { ScrollToTopButton } from 'SRC/modules/common/components/ScrollToTopButton'
import config from 'SRC/config/config.json'

import { SideBanners } from '../SideBanners'

const Layout = ({
  setAuthError,
  children,
  title,
  error,
  ad,
  isLoading,
  isMobileDevice,
  isUserSearch,
  pageUrlItems,
  setIsMobileDevice
}) => {
  React.useEffect(() => { window.addEventListener('resize', windowSizeChanged) }, [])

  useDfpSlot({
    id: (!isMobileDevice && config.banners.bottom?.slotId) || null,
    sizes: (!isMobileDevice && config.banners.bottom?.sizes) || [],
    path: !isMobileDevice && config.banners.bottom ? `/${config.banners.dfpNetworkId}/${config.banners.bottom.adUnit}` : null
  })

  // useDfpSlot({
  //   id: (!isMobileDevice && config.banners.top?.slotId) || null,
  //   sizes: (!isMobileDevice && config.banners.top?.sizes) || [],
  //   path: !isMobileDevice && config.banners.top ? `/${config.banners.dfpNetworkId}/${config.banners.top.adUnit}` : null
  // })

  const windowSizeChanged = () => {
    const width = window.innerWidth

    console.log('windowSizeChanged', width)

    if (isMobileDevice && width > 1000) setIsMobileDevice(false)
    if (!isMobileDevice && width <= 1000) setIsMobileDevice(true)
  }

  const hideError = () => setAuthError('')

  return (
    <React.Fragment>
      <div className='container'>
        <Head
          title={title}
          ad={ad}
          pageUrlItems={pageUrlItems}
          isUserSearch={isUserSearch}
        />

        {/* {!isMobileDevice ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div id={config.banners.top.slotId} style={{ width: 1000, height: 250 }} />
          </div>
        ) : null} */}

        <Header />
        <main>
          {children}
          <Sidebar />
        </main>
        <Footer />
        <CookieConsent />
        <TopLoader show={isLoading} className='top-loader' />
        <ErrorNotification hideNotification={hideError} error={error} />

        {!isMobileDevice && config.banners.background ? <SideBanners /> : null}

        <ScrollToTopButton />
      </div>

      {!isMobileDevice ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div id={config.banners.bottom.slotId} style={{ width: 1000, height: 250 }} />
        </div>
      ) : null}
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    error: getAuthError(state),
    isLoading: getAppLoading(state),
    ad: getCurrentAd(state),
    isMobileDevice: getIsMobileDevice(state)
  }
}

Layout.propTypes = {
  ad: PropTypes.object.isRequired,
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
  error: PropTypes.string,
  pageUrlItems: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  isUserSearch: PropTypes.bool,
  setAuthError: PropTypes.func.isRequired,
  setIsMobileDevice: PropTypes.func.isRequired,
}

Layout.defaultProps = {
  title: 'AutoDiler',
  error: '',
  isLoading: false
}

export default connect(mapStateToProps, { setAuthError, setIsMobileDevice })(Layout)
