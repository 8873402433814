import React from 'react'
import { withRouter } from 'next/router'
import PropTypes from 'prop-types'
import { FloatingBanner } from 'SRC/modules/common/components/FloatingBanner'
import { useDfpSlot } from 'SRC/modules/common/hooks/useDfpSlot'
import { SocServices } from 'SRC/modules/layout/Sidebar/shared/components'
import { Profile } from '../Profile'
import { AdDetail } from '../AdDetail'
import { UserDetail } from '../UserDetail'
import { AddAd } from '../AddAd'
import { BusinessUsersSmsAds } from '../BusinessUsersSmsAds'
import { getIsAuthorized } from 'SRC/modules/users/Auth/selectors'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { get404, getIsMobileDevice } from 'SRC/modules/common/selectors'
import { getInfo } from 'SRC/modules/users/detail/selectors'
import config from 'SRC/config/config.json'

const DefaultBanners = () => {
  useDfpSlot({
    id: config.banners.sidebar.default?.slotId || null,
    sizes: config.banners.sidebar.default?.sizes || [],
    path: config.banners.sidebar.default ? `/${config.banners.dfpNetworkId}/${config.banners.sidebar.default.adUnit}` : null
  })

  return (
    <div className='sidebar'>
      {config.banners.sidebar.default ? (
        <div
          id={config.banners.sidebar.default.slotId}
          style={{ width: 300, height: 250 }}
        />
      ) : null}
      <SocServices />
      <FloatingBanner />
    </div>
  )
}

class Router extends React.Component {
  getComponent = () => {
    const { router, is404, isAuthorized, isMobileDevice } = this.props

    if (is404) return !isMobileDevice ? <DefaultBanners /> : null

    switch (router.pathname) {
      case '/ad':
        return <AdDetail />
      case '/addAd':
        return !isMobileDevice ? <AddAd /> : null
      case '/':
      case '/category':
      case '/categories':
      case '/users':
        return !isMobileDevice ? <BusinessUsersSmsAds /> : null
      case '/user':
        return <UserDetail />
      case '/search':
        const { userInfo } = this.props

        return userInfo ? <UserDetail /> : !isMobileDevice ? <BusinessUsersSmsAds /> : null
      case '/profile':
      case '/editAd':
      case '/activatePromo':
        return !isMobileDevice
          ? isAuthorized
            ? <Profile />
            : <DefaultBanners />
          : null
      default:
        return !isMobileDevice ? (
          <aside className='sidebar'>
            <DefaultBanners />
          </aside>
        ) : null
    }
  }

  render() {
    return this.getComponent()
  }
}

const mapStateToProps = state => ({
  isAuthorized: getIsAuthorized(state),
  is404: get404(state),
  userInfo: getInfo(state),
  isMobileDevice: getIsMobileDevice(state)
})

Router.propTypes = {
  router: PropTypes.object,
  isAuthorized: PropTypes.bool.isRequired,
  is404: PropTypes.bool.isRequired,
  userInfo: PropTypes.shape(),
  isMobileDevice: PropTypes.bool.isRequired
}

Router.defaultProps = {
  isAuthorized: false,
  is404: false
}

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Router)
