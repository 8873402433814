import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { useDfpSlot } from 'SRC/modules/common/hooks/useDfpSlot'
import config from 'SRC/config/config.json'

export const SideBanners = () => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    const handleWindowResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  useDfpSlot({
    id: windowWidth > 1800 ? config.banners.background.left.slotId : null,
    sizes: windowWidth > 1800 ? config.banners.background.left.sizes : [],
    path: windowWidth > 1800 ? `/${config.banners.dfpNetworkId}/${config.banners.background.left.adUnit}` : null
  })

  useDfpSlot({
    id: windowWidth > 1800 ? config.banners.background.right.slotId : null,
    sizes: windowWidth > 1800 ? config.banners.background.right.sizes : [],
    path: windowWidth > 1800 ? `/${config.banners.dfpNetworkId}/${config.banners.background.right.adUnit}` : null
  })

  useDfpSlot({
    id: windowWidth > 1520 ? config.banners.background.leftMedium.slotId : null,
    sizes: windowWidth > 1520 ? config.banners.background.leftMedium.sizes : [],
    path: windowWidth > 1520 ? `/${config.banners.dfpNetworkId}/${config.banners.background.leftMedium.adUnit}` : null
  })

  useDfpSlot({
    id: windowWidth > 1520 ? config.banners.background.rightMedium.slotId : null,
    sizes: windowWidth > 1520 ? config.banners.background.rightMedium.sizes : [],
    path: windowWidth > 1520 ? `/${config.banners.dfpNetworkId}/${config.banners.background.rightMedium.adUnit}` : null
  })

  useDfpSlot({
    id: windowWidth > 1400 ? config.banners.background.leftSmall.slotId : null,
    sizes: windowWidth > 1400 ? config.banners.background.leftSmall.sizes : [],
    path: windowWidth > 1400 ? `/${config.banners.dfpNetworkId}/${config.banners.background.leftSmall.adUnit}` : null
  })

  useDfpSlot({
    id: windowWidth > 1400 ? config.banners.background.rightSmall.slotId : null,
    sizes: windowWidth > 1400 ? config.banners.background.rightSmall.sizes : [],
    path: windowWidth > 1400 ? `/${config.banners.dfpNetworkId}/${config.banners.background.rightSmall.adUnit}` : null
  })

  if (windowWidth > 1800) {
    return (
      <React.Fragment>
        <div className='banner-300-768-left' key='banner-300-768-left'>
          <div
            id={config.banners.background.left.slotId}
            style={{ width: 300, height: 768 }}
          />
        </div>

        <div className='banner-300-768-right' key='banner-300-768-right'>
          <div
            id={config.banners.background.right.slotId}
            style={{ width: 300, height: 768 }}
          />
        </div>
      </React.Fragment>
    )
  }

  if (windowWidth > 1520) {
    return (
      <React.Fragment>
        <div className='banner-160-600-left' key='banner-160-600-left'>
          <div
            id={config.banners.background.leftMedium.slotId}
            style={{ width: 160, height: 600 }}
          />
        </div>

        <div className='banner-160-600-right' key='banner-160-600-right'>
          <div
            id={config.banners.background.rightMedium.slotId}
            style={{ width: 160, height: 600 }}
          />
        </div>
      </React.Fragment>
    )
  }

  if (windowWidth > 1400) {
    return (
      <React.Fragment>
        <div className='banner-120-600-left' key='banner-120-600-left'>
          <div
            id={config.banners.background.leftSmall.slotId}
            style={{ width: 120, height: 600 }}
          />
        </div>

        <div className='banner-120-600-right' key='banner-120-600-right'>
          <div
            id={config.banners.background.rightSmall.slotId}
            style={{ width: 120, height: 600 }}
          />
        </div>
      </React.Fragment>
    )
  }

  return null
}
