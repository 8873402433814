import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDfpSlot } from 'SRC/modules/common/hooks/useDfpSlot'
import { connect } from 'react-redux'
import { getAdsLoading, getPaidAds } from 'SRC/modules/ads/list/selectors'
import config from 'SRC/config/config.json'

export const FloatingBanner = () => {
  const stickyRef = React.useRef()
  const bannerCRef = React.useRef()

  useDfpSlot({
    id: config.banners.floating.slotId || null,
    sizes: config.banners.floating.sizes || [],
    path: config.banners.floating ? `/${config.banners.dfpNetworkId}/${config.banners.floating.adUnit}` : null
  })

  useEffect(() => {
    document.addEventListener('scroll', onScroll)

    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [])

  const onScroll = () => {
    const scrollY = window.scrollY

    const previousSibling = stickyRef.current ? stickyRef.current.previousSibling : null

    if (previousSibling) {
      const topPosition = previousSibling.offsetTop + previousSibling.offsetHeight

      let footer
      const findFooterArr = document.getElementsByTagName('footer')
      footer = findFooterArr.length && findFooterArr[0]

      if (stickyRef.current) {
        if (scrollY > topPosition && footer && footer.offsetTop - topPosition > stickyRef.current.offsetHeight + 5) {
          stickyRef.current.style.position = 'fixed'
          if (footer.offsetTop - scrollY > stickyRef.current.offsetHeight + 5) {
            stickyRef.current.style.top = '5px'
            stickyRef.current.style.bottom = 'auto'
          } else {
            stickyRef.current.style.top = 'auto'
            stickyRef.current.style.bottom = `${footer.offsetHeight + 330}px`
          }
        } else {
          stickyRef.current.style.position = 'static'
        }
      }
    }
  }

  return (
    <div ref={stickyRef} style={{ width: 300, height: 600, overflow: 'auto' }}>
      <div id={config.banners.floating.slotId} style={{ width: 300, height: 600 }} />
    </div>
  )
}

FloatingBanner.propTypes = {
  router: PropTypes.shape({
    pathname: PropTypes.string,
    asPath: PropTypes.string
  }).isRequired,
  type: PropTypes.string.isRequired,
  paidAdsCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAdsLoading: PropTypes.bool.isRequired
}
