import { useEffect } from 'react'

export const useDfpSlot = ({ path, sizes, id }) => {
  useEffect(() => {
    if (!path || !id) return

    const googletag = window.googletag || {}

    googletag.cmd = googletag.cmd || []

    googletag.cmd.push(() => {
      googletag.display(id)
    });
  }, [path, id, sizes])
}
